



































































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Organization} from '@/model/resource/Organization'

@Component({})
export default class CaOrganizationInfo extends Vue {
  @Prop({type: Boolean, default: false}) isBoxVersion!: boolean
  @Prop({type: Organization, required: true}) organization!: Organization

  mounted() {}
}
