var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isBoxVersion)?_c('div',{staticClass:"organization-info-card-mobile"},[_c('img',{staticClass:"h-6 w-6",attrs:{"alt":"logo","src":require("@/assets/img/information.svg")}}),_c('div',{staticClass:"text-left ml-7 mr-5 mb-3"},[_c('div',{staticClass:"text-sm mt-1"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationLocationCard.information'))+" ")]),_c('div',{staticClass:"verti font-medium text-2xl pr-2"},[(_vm.organization.location)?_c('div',{staticClass:"horiz mt-4"},[_c('span',{staticClass:"font-medium text-base text-left w-full",domProps:{"innerHTML":_vm._s(
            _vm.$t('components.organizationLocationCard.location', {
              value: _vm.organization.location,
            })
          )}})]):_vm._e(),(_vm.organization.establishedYear)?_c('div',{staticClass:"horiz mt-2"},[_c('div',{staticClass:"font-medium text-base"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationLocationCard.yearEstablished'))+" ")]),_c('div',{staticClass:"font-medium ml-1 text-base font-bold w-full"},[_vm._v(" "+_vm._s(_vm.organization.establishedYear)+" ")])]):_vm._e(),(_vm.organization.repositoryUrl)?_c('div',{staticClass:"horiz mt-2 w-11/12"},[_c('div',{staticClass:"font-medium text-base"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationLocationCard.website'))+" ")]),(_vm.organization.repositoryUrl)?_c('a',{staticClass:"font-medium ml-1 text-base w-11/12 font-bold break-all",attrs:{"href":_vm.organization.repositoryUrl}},[_vm._v(" "+_vm._s(_vm.organization.repositoryUrl)+" ")]):_vm._e()]):_vm._e(),(_vm.organization.organizationSocial.length > 0)?_c('div',{staticClass:"items-center mt-4 grid grid-cols-5 gap-x-3"},_vm._l((_vm.organization.organizationSocial),function(item,i){return _c('a',{key:i,staticClass:"mr-2 my-2 p-2 bg-cutty-sark rounded-lg transition transform hover:scale-110 h-12 w-12",attrs:{"href":item.url,"target":"_blank"}},[_c('img',{staticClass:"h-8 w-8 object-contain mr-2",attrs:{"src":item.socialType.iconUrl,"alt":"Icon"}})])}),0):_vm._e()])])]):_c('div',{staticClass:"organization-info-card"},[_c('div',{staticClass:"horiz items-center"},[_c('img',{staticClass:"h-6 w-6",attrs:{"alt":"logo","src":require("@/assets/img/information.svg")}}),_c('div',{staticClass:"font-medium ml-1 text-xl"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationLocationCard.information'))+" ")])]),_c('div',{staticClass:"verti w-auto"},[(_vm.organization.location)?_c('div',{staticClass:"horiz mt-2"},[_c('span',{staticClass:"font-medium ml-2 text-lg text-left w-full",domProps:{"innerHTML":_vm._s(
          _vm.$t('components.organizationLocationCard.location', {
            value: _vm.organization.location,
          })
        )}})]):_vm._e(),(_vm.organization.establishedYear)?_c('div',{staticClass:"horiz mt-2"},[_c('div',{staticClass:"font-medium ml-2 text-lg"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationLocationCard.yearEstablished'))+" ")]),_c('div',{staticClass:"font-medium ml-1 text-lg font-bold"},[_vm._v(" "+_vm._s(_vm.organization.establishedYear)+" ")])]):_vm._e(),(_vm.organization.repositoryUrl)?_c('div',{staticClass:"horiz mt-2"},[_c('div',{staticClass:"font-medium ml-2 text-lg"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationLocationCard.website'))+" ")]),(_vm.organization.repositoryUrl)?_c('a',{staticClass:"font-medium ml-1 text-lg font-bold text-left weight-1",attrs:{"href":_vm.organization.repositoryUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.organization.repositoryUrl)+" ")]):_vm._e()]):_vm._e(),(_vm.organization.organizationSocial.length > 0)?_c('div',{staticClass:"grid grid-cols-5 ml-2 mt-4"},_vm._l((_vm.organization.organizationSocial),function(item,i){return _c('a',{key:i,staticClass:"m-1 p-2 bg-cutty-sark rounded-lg justify-items-center transition transform hover:scale-110 h-10 w-10",attrs:{"href":item.url,"target":"_blank"}},[_c('img',{staticClass:"h-6 w-6 object-contain",attrs:{"src":item.socialType.iconUrl,"alt":"Icon"}})])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }