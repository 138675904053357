


























































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {InputCheckbox} from '@simpli/vue-input'
import {CAController, CAIterable, CAType} from '@/app/CAController'

@Component({
  components: {InputCheckbox},
})
export default class CaNav extends Vue {
  @Prop({type: CAController, required: true}) controller!: CAController

  get allActivityVolume() {
    return this.controller.allActivityVolume
  }

  isRepository(item: CAIterable) {
    return item.caType == CAType.REPOSITORY
  }

  isRepoSelected(index: number) {
    if (!this.controller.isRepositorySelection) return
    return this.controller.selectedRepositoryIndex === index
  }

  async backToAllOrganizations() {
    await this.controller.deselectOrganizations()
  }

  backToOrganizationView() {
    this.controller.deselectRepositories()
  }

  iterableColor(value: CAIterable) {
    return `background-color: #${value.colorHex}`
  }

  selectItem(item: CAIterable, index: number) {
    if (this.isRepository(item)) {
      this.controller.selectRepository(index)
    } else {
      this.controller.selectOrganization(index)
    }
  }
}
