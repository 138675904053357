





































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class CaLineCount extends Vue {
  @Prop({type: Boolean, default: false}) isBoxVersion!: boolean
  @Prop({type: Number, required: true}) value!: number
}
