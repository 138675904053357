






























































































































































import {Component, Mixins, Prop} from 'vue-property-decorator'
import {Modal} from '@simpli/vue-modal'
import {CAController} from '@/app/CAController'
import CaWheel from '@/components/ca/CaWheel.vue'
import CaNav from '@/components/ca/CaNav.vue'
import CaLineCount from '@/components/ca/CaLineCount.vue'
import CaCommitCount from '@/components/ca/CaCommitCount.vue'
import CaTopLanguages from '@/components/ca/CaTopLanguages.vue'
import CaStarCount from '@/components/ca/CaStarCount.vue'
import CaContributorCount from '@/components/ca/CaContributorCount.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import CaOverviewTitle from '@/views/ca-overview/CaOverviewTitle.vue'
import CaOverviewSliderMobile from '@/views/ca-overview/CaOverviewSliderMobile.vue'
import CaOverviewMobileFilter from '@/views/ca-overview/CaOverviewMobileFilter.vue'
import CaOrganizationInfo from '@/components/ca/CaOrganizationInfo.vue'
import CaRepositorytInfo from '@/components/ca/CaRepositorytInfo.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

@Component({
  components: {
    CaRepositorytInfo,
    CaOverviewMobileFilter,
    CaOverviewSliderMobile,
    CaOverviewTitle,
    CaContributorCount,
    CaStarCount,
    CaTopLanguages,
    CaCommitCount,
    CaLineCount,
    CaNav,
    CaWheel,
    CaOrganizationInfo,
    Modal,
    Swiper,
    SwiperSlide,
  },
})
export default class CaOverview extends Mixins(MixinScreenSize) {
  @Prop({type: CAController, required: true}) controller!: CAController

  private showModal: boolean = false

  get showOrganizationOrRepositoryInfo(): Boolean {
    if (this.controller.selectedRepository) {
      return !!this.controller.selectedRepository.description
    }

    const org = this.controller.selectedOrganization
    return !!(org?.repositoryUrl || org?.establishedYear || org?.location)
  }

  toggleModal(): void {
    this.showModal = !this.showModal
  }
}
