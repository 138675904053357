

































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {LanguageItem} from '@/model/stats/GithubActivityStats'

@Component({})
export default class CaTopLanguages extends Vue {
  @Prop({type: Boolean, default: false}) isBoxVersion!: boolean
  @Prop({type: Array, required: true}) value!: LanguageItem[]
}
